// Utils
import { getPathImage } from '@/utils'
// Components
import CardCentered from '@/components/ui/CardCentered'

export default {
  name: 'BrandCardCentered',
  components: { CardCentered },
  props: {
    title: String,
    subtitle: String
  },
  computed: {
    /**
     * Return brand image
     *
     * @return {string} - brand image
     */
    getBrandImage() {
      return getPathImage('brand-form.png', true)
    },
    /**
     * Options to CardCentered component
     */
    getCardVOptions() {
      return {
        width: '100%',
        'max-width': '500px'
      }
    }
  }
}
